@import "./../../../assets/styles/mixins";
@import "./../../../assets/styles/helpers";

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 5.625rem;
  border-top: 1px solid $header_border_color;
  border-radius: 0 0 50px 50px;
  background-color: $body;

  p {
    margin-right: 45px;
    @include font (400, start, Montserrat-Regular, 1rem, 1.25rem);
    color: $grey_1;
  }
}