@import './../../../assets/styles/mixins';
@import './../../../assets/styles/helpers';

.editable_span{


  &_main {

    display: flex;
   // justify-content: center;
    align-items: center;
    @include font(500, start, Montserrat-Regular, 1rem, 1.25rem);


    & span {
      margin-right: 1rem;
      word-wrap: break-word;
    }
  }

  input {
    border: 1px solid #cacaca;
    border-radius: 10px;
  }
}