@import './../fonts/fonts';

//миксин для марджинов и паддингов
@mixin adaptiv_value($property, $startSize, $minSize) {
  $addSize: $startSize - $minSize;

  #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 320px) / #{1920 - 320}));
}

////COMMON
@mixin font ($weight, $text-align, $family, $font-size, $line-height){
  font-family: $family;
  font-weight: $weight;
  font-size: $font-size;
  line-height: $line-height;
  text-align: $text-align;
}

//breakpoint
$desctop: 1440px;
$laptop: 1200px;
$laptop-min: 1024px;
$mobile: 768px;
$mobile-min: 576px;
$mobile-extra-min: 360px;


@mixin desc {
  @media screen and (min-width: $desctop) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: $laptop) {
    @content;
  }
}

@mixin laptop-min {
  @media screen and (max-width: $laptop-min) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $mobile) {
    @content;
  }
}

@mixin mobile-min {
  @media screen and (max-width: $mobile-min) {
    @content;
  }
}

@mixin mobile-extra-min {
  @media screen and (max-width: $mobile-extra-min) {
    @content;
  }
}






