@import './../../../../assets/styles/helpers';
@import './../../../../assets/styles/mixins';

.modal_wrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 6;
}

.modal_body {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 50px auto;
  background-color: $white;
  padding: 1.875rem;
  max-height: 90vh;
  overflow: auto;

  h2 {
    @include font(500, start, Montserrat-Medium, 1.25rem, 1.5rem);
  }

  h3 {
    margin: 1.25rem 0;
    @include font(400, center, Montserrat-Regular, 1rem, 1.5rem);
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  cursor: pointer;

  img {
    overflow: hidden;
    width: 100%;
  }
}