@import './../../../assets/styles/helpers';
@import './../../../assets/styles/mixins';

.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  width: 100%;
  height: 70px;
  padding: 5px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  background-color: #DCEEFF;
  margin-right: 1.5625rem;
  cursor: pointer;
  @include font(600, center, Montserrat-Regular, 1rem, 1.25rem);

  div {
    word-break: break-all;
  }
}

.active {
  background: #F6F8FA;
  @include font(500, center, Montserrat-Regular, 1rem, 1.25rem);
}

@media screen and (max-width: 700px) {
  .tab {
    margin-right: 5px;
  }
}

@media screen and (max-width: 580px) {
  .tab {
    font-size: 9px;
    margin-right: 1px;
  }
}