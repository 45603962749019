.inserted_image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;

  & img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    flex-shrink: 0;
  }
}

