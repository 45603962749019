@import "./../../../assets/styles/mixins";
@import './../../../assets/styles/helpers';

.button_without_image {
  display: block;
  //height: 46px;
  height: 2.875rem;
  background-color: $button_with_image;
  border-radius: 5px;
  color: $white;
  border: none;
  cursor: pointer;
  @include font(700, center, Montserrat-Bold, 1rem, 1.25rem);
}

.disabled_button {
  opacity: .5;
}