@import './../../../assets/styles/helpers';
@import './../../../assets/styles/mixins';

.inserted_image_round {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 50%;
  background-color: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  & img, div {
    line-height: 1.625rem;
    display: block;
    height: 1.625rem;
    vertical-align: middle;
    text-align: center;
    @include font(500, center, Montserrat-Regular, 1.125rem, 1.625rem);
  }
}

.active_yellow {
  background-color: $yellow-lighter;
}

.active_blue {
  background-color: $active_blue;
}

.active_red {
  background-color: $active_red;
}