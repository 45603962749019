@import './../../../../../assets/styles/helpers';
@import './../../../../../assets/styles/mixins';

.modal_wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(163, 163, 163, .5);
}

.modal_body {
  width: 80%;
  max-width: 1150px;
  margin: 20rem auto;
  min-height: 305px;
  background-color: $white;
}

.modal_body_header {
  width: 100%;
  height: 40px;
  position: relative;
  background: rgba(255, 194, 14, 0.2);
  border-radius: 20px 20px 0 0;

  &_close_button {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 20px;
    height: 20px;
    overflow: hidden;
    cursor: pointer;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.modal_body_content {
  padding: 1.875rem 3.125rem;

  &_title {
    margin-left: 90px;
    margin-bottom: 3.125rem;
    @include font(700, start, Montserrat-Regular, 1.25rem, 1.5rem);
  }

  &_info {
    display: flex;
    align-items: center;

    .image_wrapper {
      width: 70px;
      height: 70px;
      overflow: hidden;
      margin-right: 20px;
      flex-shrink: 0;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .text_wrapper {
      min-height: 70px;
      @include font(500, start, Montserrat-Regular, 1.125rem, 1.375rem);

      & > p:first-child {
        margin-bottom: 25px;
      }
    }
  }

  &_button {
    margin-top: 10px;

    & > button {
      margin-left: auto;
    }
  }
}

@media screen and (max-width: 500px) {
  .modal_body {
    width: 95%;
  }

  .modal_body_content_title {
    margin-left: 0;
  }

  .modal_body_content {
    padding: 1rem;
  }

  .modal_body_content_info {
    .image_wrapper {
      margin-right: 10px;
    }
  }
}

