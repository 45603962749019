@import "./../../../../../assets/styles/helpers";
@import './../../../../../assets/styles/mixins';

.title_hint {
  @include font(500, start, Montserrat-Medium, 1.125rem, 1.375rem);
  margin-top: 2.65rem;
}

.choice_wrapper {
  margin-top: 2.8125rem;
  @include font(500, start, Montserrat-Medium, 1.125rem, 1.375rem);

  & input {
    margin-right: 1.125rem;
  }
}

.input_text_wrapper {
  margin-top: 32px;

  & > label {
    display: block;
    margin-bottom: 1.5rem;
    @include font(600, start, Montserrat-SemiBold, 1rem, 1.25rem);
  }

  & > input {
    width: 100%;
    height: 51px;
    color: $teamManagement_modal_window_form_text_color;
    border: 0.5px solid #D3D1D1;
    border-radius: 5px;
    @include font(500, start, Montserrat-Medium, 0.875rem, 1.0625rem);
  }

}

.error_message {
  margin-top: 10px;
  color: red;
  @include font(600, start, Montserrat-SemiBold, 1rem, 1.25rem);
}

.button_wrapper {
  width: 100%;
  max-width: 326px;
  margin: 2.375rem auto 0;

  & > button {
    margin: 0 auto;
  }
}

@media screen and (max-width: 500px) {
  .input_text_wrapper {
    margin-top: 10px;
  }

  .choice_wrapper {
    margin-top: 10px;
  }

}