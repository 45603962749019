@import './../../../assets/styles/helpers';
@import './../../../assets/styles/mixins';

.switch_notifications_window {
  max-height: 85vh;
  overflow-y: auto;
  //z-index: 6;
  //position: absolute;
  //right: 0;
  //top: 100%;
  max-width: 100%;
  width: 38.75rem;
  border: 1px solid #D6D1D1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: $white;

  &_header {
    padding: 10px 0;
    @include font(500, center, Montserrat-Regular, 1rem, 1.25rem);
  }

  &_filters {
    display: flex;
    justify-content: space-between;
    padding: 1.563rem 1.375rem;

    p {
      @include font(400, start, Montserrat-Regular, 0.75rem, 0.938rem);
      color: $link;
      cursor: pointer;
    }
  }

  &_body {
    padding: 0.688rem 2.313rem 3.375rem 0.938rem;
    background-color: $notifications_menu_body;
  }
}

.switch_notifications_separator {
  width: 100%;
  height: 0;
  border: 1px solid #C5C0C0;
}
