@import './../../../assets/styles/helpers';
@import './../../../assets/styles/mixins';

.header {
  position: relative;
  //z-index: -1;
  display: flex;
  align-items: center;
  //justify-content: space-between;
  //padding: 25px 45px;
  padding: 1.5625rem 2.8125rem;
  border-bottom: 1px solid $header_border_color;

  h1 {
    margin-right: auto;
  }


  &_buttons {
    position: relative;
    display: flex;
    width: 115px;
    //justify-content: space-between;
    justify-content: flex-end;

    //&>div:nth-child(2) {
    //  @include font (400, center, Montserrat-Regular, 1.125rem, 1.375rem);
    //}

    &>div:nth-child(1) {
      @include font (400, center, Montserrat-Regular, 1.125rem, 1.375rem);
    }

    &_wrapper {
      display: flex;

      //пока закомментила, пока нет кнопки для нотификашек
      &>div:first-child {
        margin-right: 10px;
      }
    }
  }
}

.header_buttons .role {
  position: absolute;
  width: 100%;
  left: 30%;
  bottom: -17px;
  color: $switch_account_blue;
  @include font (400, center, Montserrat-Regular, .7rem, .7rem);
}
