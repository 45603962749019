@import "./../../../assets/styles/mixins";
@import './../../../assets/styles/helpers';

.button_with_image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $button_with_image;
  border-radius: 5px;
  color: $white;
  border: none;
  cursor: pointer;
  @include font(700, center, Montserrat-Bold, 1rem, 1.25rem);

  &_img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 21px;
    height: 21px;
    overflow: hidden;
    flex-shrink: 0;

    img {
      display: block;
      width: 100%;
    }
  }

  &_value {
    text-align: start;
    margin-left: 10px;
  }
}

.disabled_button {
  opacity: .5;
}