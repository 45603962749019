@font-face {
  font-family: "Poppins-Light";
  src: url("../fonts/Poppins-Light.ttf") format("truetype"),
  url("../fonts/Poppins-Light.otf") format("opentype");
}

@font-face {
  font-family: aller std thin;
  src: url("../fonts/Poppins-Light.ttf") format("truetype"),
  url("../fonts/Poppins-Light.otf") format("opentype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins-Regular.ttf") format("truetype"),
  url("../fonts/Poppins-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/Poppins-Medium.ttf") format("truetype"),
  url("../fonts/Poppins-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Poppins-Semibold";
  src: url("../fonts/Poppins-SemiBold.ttf") format("truetype"),
  url("../fonts/Poppins-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/Poppins-Bold.ttf") format("truetype"),
  url("../fonts/Poppins-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("../fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("../fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("../fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("../fonts/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Light";
  src: url("../fonts/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("../fonts/Montserrat-Medium.ttf") format("truetype");
}


@font-face {
  font-family: "Inter-Bold";
  src: url("../fonts/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Light";
  src: url("../fonts/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Medium";
  src: url("../fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Regular";
  src: url("../fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url("../fonts/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Thin";
  src: url("../fonts/Inter-Thin.ttf") format("truetype");
}