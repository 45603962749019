@import './../../../../../assets/styles/mixins';
@import './../../../../../assets/styles/helpers';



.team_information {
  width: 100%;
  position: relative;

  &_leaders {
    display: flex;
    justify-content: space-between;
    align-items: center;
   // padding: 20px 55px 20px 35px;
    padding: 1.25rem 3.4375rem 1.25rem 2.1875rem;
    margin-bottom: 3.4375rem;
    background-color: $white;
  }

  &_link {
    display: flex;
    justify-content: flex-end;
    margin: 1.25rem 0;
    color: $link;
    cursor: pointer;
    @include font(700, end, Montserrat-Bold, 1rem, 1.25rem);

    &>div:first-child {
      margin-right: 9px;
    }
  }


  &_leaders_info {
    width: 100%;
    max-width: 400px;

    &_item {
      display: flex;
      margin-bottom: 2.5rem;
      align-items: center;

      &:last-child {
        margin-bottom: 1.25rem;
      }

      & > div:first-child {
        width: 50%;
       // margin-right: 3rem;
        @include font(300, start, Montserrat-Light, 1rem, 1.25rem)
      }

      & > div:nth-child(2) {
        margin: 0 1rem;
       // margin-right: 1.25rem;
        @include font(500, start, Montserrat-Medium, 1rem, 1.25rem)
      }

      & > div:nth-child(4) {
        margin-left: 1rem;
      }

      & > div:last-child {
       // margin-left: 1rem;
      }
    }
  }


  &_members {
    //padding: 20px 20px 20px 35px;
    padding: 1.25rem 1.25rem 1.25rem 2.1875rem;
    background-color: $white;
    overflow-x: auto;

    & h3 {
      margin-bottom: 1.875rem;
      @include font(600, start, Montserrat-SemiBold, 1rem, 1.25rem)
    }
  }

  &_members::-webkit-scrollbar {
    height: 10px;
    background-color: #f1ecec;
  }

  &_members::-webkit-scrollbar-thumb {
    background-color: $link;
    border-radius: 20px;
    box-shadow: inset 1px 1px 10px #f3faf7;
  }
}

@media screen and (max-width: 550px) {
  .team_information_leaders,.team_information_members {
    padding: 1rem;

    button {
      margin-left: 10px;
      width: 25%;
    }
  }

  .team_information_leaders_info_item {
    //&>div:first-child {
    //  width: 80px;
    //  max-width: 80px;
    //  min-width: 80px;
    //  margin-right: 1.5rem;
    //}

  }



}