@import './../../../../../assets/styles/helpers';
@import './../../../../../assets/styles/mixins';

.message_item_wrapper {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.message_item {
  width: 100%;
  border-radius: 20px;
  padding: 0.9375rem;
  cursor: pointer;

  &_text {
    width: 100%;
    margin-top: 5px;
    @include font(400, start, Montserrat-Regular, 1.25rem, 1.875rem);

    &::-webkit-scrollbar {
      width: 0;
    }
  }
}


.message_item_header {
  display: flex;
  justify-content: space-between;

  .message_item_header_block_with_ava {
    display: flex;

    & > div:first-child {
      margin-right: 1.125rem;
    }

    .message_item_header_block_with_ava_info_block {
      &_name {
        margin-top: 3px;
        @include font(600, start, Montserrat-Regular, 1.25rem, 1.5rem);
      }

      &_time {
        margin-top: 3px;
        @include font(500, start, Montserrat-Regular, 1rem, 1.25rem);
        color: #BDBDBD;
      }
    }
  }

  .message_item_header_block_from {
    @include font(500, start, Montserrat-Regular, 1.25rem, 1.875rem);
    color: #787878;
    font-style: italic;
    margin-left: 10px;
  }
}

.read_indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #0B8AFF;;
  margin-right: 1.25rem;
}

.ava {
  width: 68px;
  height: 68px;
  overflow: hidden;
  border-radius: 50%;

  & img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 500px) {
  .ava {
    width: 40px;
    height: 40px;
  }

  .read_indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #0B8AFF;;
    margin-right: 5px;
  }
}

