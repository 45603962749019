@import './../../../../../assets/styles/mixins';
@import './../../../../../assets/styles/helpers';

.info_icon {
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  top: -1.5rem;
  right: 0;
  border-radius: 50%;
  border: 1px solid $link;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.info_message {
  position: absolute;
  max-width: 18.75rem;
  // width: 40%;
  // bottom: -50%;
  bottom: 70px;
  z-index: 99999;
  background-color: #EFEDED;
  color: $black;
  text-align: justify;
  padding: 1rem;
  border-radius: 1rem;
  @include font(600, center, Montserrat-Regular, .8rem, 1rem);
}

.shown {
  visibility: visible;
  opacity: 1;
  transition: visibility .5s linear, opacity .5s linear;
}

.unshown {
  visibility: hidden;
  opacity: 0;
  transition: visibility .5s linear, opacity .5s linear;
}

@media screen and (max-width: 1000px) {
  .info_message {
    bottom: 45px;
  }

  .info_icon {
    top: 0;
    right: -1.4rem;
  }
}