@import './../../../../assets/styles/helpers';
@import './../../../../assets/styles/mixins';

.motivation {
  width: 100%;
  margin-bottom: 20px;

  &_header {
    width: 100%;
    height: 40px;
    background-color: $motivation_header;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &_body {
    &_text {
      margin: 0 0 2.6875rem 1.875rem;
      @include font (500, start, Montserrat-Regular, 1.25rem, 1.875rem);
    }
  }
}

.posts_separator {
  margin-bottom: 15px;
  @include font (400, center, Montserrat-Regular, 1.5625rem, 1.875rem);

  p::before {
    content: '';
    background: url('./../../../../assets/images/coachingCorner/arrowDown.png');
    display: inline-block;
    width: 25px;
    height: 14px;
    margin-right: 15px;
  }
}

.block_with_new_messages {}

.block_with_old_messages {}