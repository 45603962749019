@import './../../../../assets/styles/helpers';
@import './../../../../assets/styles/mixins';

.notification_item_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;

  a {
    color: #0B8AFF;
  }
}

.notification_item_wrapper_point {
  width: 1rem;
  margin-right: 0.938rem;
  cursor: pointer;

  div {
    width: 10px;
    height: 10px;
  }
}

.notification_item_wrapper_context {
  width: 100%;
  background-color: $white;
  border-radius: 5px;
  padding: 1.375rem 1.25rem 1rem 1.25rem;
}

.read_point {
  background-color: $notifications_menu_body;
  border: 1px solid $link;
  border-radius: 50%;
}

.unread_point {
  background-color: $red;
  border-radius: 50%;
}

.header {
  display: flex;
  align-items: center;
  @include font(600, start, Montserrat-Regular, 0.75rem, 0.938rem);

  &_img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23px;
    height: 23px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 10px;
    flex-shrink: 0;

    img {
      display: block;
      width: 60%;
      height: 60%;
    }
  }
}

.text {
  cursor: pointer;
  margin-left: 36px;
  margin-top: 10px;
  @include font(400, start, Montserrat-Regular, 0.75rem, 0.938rem);
}

.action_button {
  width: 100px;
  min-height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include font(700, center, Montserrat-Regular, 0.75rem, 0.938rem);
  background: rgba(38, 151, 254, 0.1);
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  padding: 5px 0;
  transition: .5s;

  &:hover {
    background: rgba(0, 219, 113, .4);
  }
}

.btn_span {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 219, 113, .8);
  color: white;
  padding: 5px;
  border-radius: 5px;
  display: none;
  @include font(400, center, Montserrat-Regular, 0.875rem, 1rem);
}
