@import './../../../../assets/styles/mixins';
@import './../../../../assets/styles/helpers';

* {
  box-sizing: border-box;
}

.department_information {
  position: relative;
  width: 100%;

  &_leaders {
    margin-bottom: 3.4375rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.25rem;
    background-color: $white;
  }

  &_leaders_info {
    width: 50%;

    &_item {
      display: flex;
      align-items: center;
      margin-bottom: 2.5rem;

      &:last-child {
        margin-bottom: 1.25rem;
      }

      & > div:first-child {
        width: 100%;
        max-width: 180px;
        @include font(300, start, Montserrat-Light, 1rem, 1.25rem)
      }

      & > div:nth-child(2) {
        margin-right: 1.25rem;
        @include font(500, start, Montserrat-Medium, 1rem, 1.25rem)
      }
    }
  }

  &_leaders_buttons {
    position: relative;
    width: 40%;
    display: flex;
    justify-content: flex-end;

    button {
      position: relative;
    }

    button:first-child {
      margin-right: 1.25rem;
    }
  }

  &_link {
    display: flex;
    justify-content: flex-end;
    margin: 1.25rem 0;
    color: $link;
    cursor: pointer;
    @include font(700, end, Montserrat-Bold, 1rem, 1.25rem);

    & > div:first-child {
      margin-right: 9px;
    }
  }

  &_members {
    background-color: $white;
    padding: 1.25rem 1.25rem 1.25rem 2.1875rem;
    overflow-x: auto;

    & h3 {
      margin-bottom: 1.875rem;
      @include font(600, start, Montserrat-SemiBold, 1rem, 1.25rem)
    }
  }

  &_members::-webkit-scrollbar {
    height: 10px;
    background-color: #f1ecec;
  }

  &_members::-webkit-scrollbar-thumb {
    background-color: $link;
    border-radius: 20px;
    box-shadow: inset 1px 1px 10px #f3faf7;
  }

  &_members {
    scrollbar-color: #008de4 #fafafa;
    scrollbar-width: thin;
  }
}

.info_icon {
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  top: -1.5rem;
  right: 0;
  border-radius: 50%;
  border: 1px solid $link;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.info_message {
  position: absolute;
  max-width: 18.75rem;
  // width: 40%;
  bottom: -50%;
  left: -50%;
  z-index: 99999;
  background-color: #EFEDED;
  color: $black;
  text-align: justify;
  padding: 1rem;
  border-radius: 1rem;
  @include font(600, center, Montserrat-Regular, .8rem, 1rem);
}

.shown {
  visibility: visible;
  opacity: 1;
  transition: visibility .5s linear, opacity .5s linear;
}

.unshown {
  visibility: hidden;
  opacity: 0;
  transition: visibility .5s linear, opacity .5s linear;
}

@media screen and (max-width: 1000px) {
  .department_information_leaders_info {
    width: 65%;
  }

  .department_information_leaders_info_item {
    & > div:first-child {
      width: 85px;
      max-width: 85px;
      min-width: 85px;
      margin-right: 1rem;
    }
  }

  .department_information_leaders_buttons {
    flex-direction: column;
    width: 31%;
    margin: 0 1rem;

    & > button:first-child {
      margin-bottom: 1em;
    }

  }

  //.info_icon {
  //  top: 0;
  //  right: -1.4rem;
  //}
}

@media screen and (max-width: 580px) {
  .department_information_leaders_info_item {
    & > div:first-child {
      width: 80px;
      max-width: 80px;
      min-width: 80px;
      margin-right: .2rem;
    }
  }
}