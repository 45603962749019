@import "./../../../../assets/styles/mixins";
@import './../../../../assets/styles/helpers';

.membership {
  display: flex;
  align-items: center;
  margin: 0.9375rem;

  & > div:first-child {
    @include font(400, center, Montserrat-Regular, 1.125rem, 1.375rem);
    text-align: center;
  }

  &_info {
    margin-left: 1.875rem;

    & > div:first-child {
      @include font(400, start, Montserrat-Regular, 1rem, 1.25rem);
    }

    & > div:nth-child(2) {
      @include font(300, start, Montserrat-Regular, 0.875rem, 1.0625rem);
      color: $switch_account_grey;
    }

    & > div:last-child {
      @include font(300, start, Montserrat-Regular, 0.75rem, 0.9375rem);
      color: $switch_account_blue;
    }

    & > div {
      margin-bottom: 5px;
    }
  }
}

.switch_account_separator {
  width: 100%;
  height: 0;
  border: 1px solid #C5C0C0;
}