@import '../../../../assets/styles/helpers';
@import '../../../../assets/styles/mixins';

.insight_item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
  @include font(500, start, Montserrat-Regular, 1rem, 1.25rem);

  & > div:first-child {
    margin-right: 2.5rem;
    flex-shrink: 0;
  }
}

.insight_item_info {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.25rem 1.5625rem 0.9375rem 2.5rem;
  background-color: $white;

  &_text_block {
    width: 90%;
    margin-right: 10%;
    cursor: pointer;
    @include font(600, justify, Montserrat-Regular, 1rem, 1.25rem);
  }

  &_helpful_block {
    width: 100px;
    flex-shrink: 0;
    align-self: flex-end;
  }

  &_helpful_block_mobile {
    display: none;
    max-width: 100px;
    flex-shrink: 0;
  }

  &_icons_block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: auto;
    flex-shrink: 0;
    min-height: 100%;
    align-self: stretch;

    &_time {
      margin-bottom: 3.4375rem;
    }

    &_icons {
      display: flex;
      align-items: center;

      & > div:nth-child(1),
      & > div:nth-child(2) {
        margin-right: 1.625rem;
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .insight_item > div:first-child {
    margin-right: 10px;
  }

  .insight_item_info {
    padding: 10px;
    flex-direction: column;
  }

  .insight_item_info_text_block {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .insight_item_info_helpful_block {
    display: none;
  }

  .insight_item_info_helpful_block_mobile {
    display: block;
  }

  .insight_item_info_icons_block_time {
    margin-bottom: 15px;
  }
}