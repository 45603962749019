@import './../../../../../../assets/styles/helpers';
@import './../../../../../../assets/styles/mixins';

.node_item_wrapper {
}

.info_block {
 // position: relative;
  width: 100%;
  //padding: 1.313rem 0.938rem 1.625rem;
  padding: 0 15px 35px 26px;
  //display: flex;
  //align-items: center;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  &_info_text {
    // width: 80%;
    padding: 10px;
   // position: absolute;
   // top: 40px;
   // right: 20px;
    margin-top: 5px;
    margin-bottom: -30px;
    display: none;
   // z-index: 6;
    background-color: #D3D1D1;
    border-radius: 20px;
    @include font(600, center, Montserrat-Regular, 1.125rem, 1.375rem);
  }

  &_icons_wrapper {
    //position: absolute;
    //top: 5px;
    //right: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .roles_number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      margin-right: -5px;
      background-color: #D3D1D1;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      overflow: hidden;
      cursor: pointer;
      @include font(500, center, Montserrat-Regular, 12px, 14px);
    }

    .add_icon {
      width: 27px;
      height: 27px;
      overflow: hidden;
      cursor: pointer;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

  }

  &_foto {
    //width: 4.875rem;
    //height: 4.875rem;
    width: 78px;
    height: 78px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    //margin-right: 1.375rem;
    margin-right: 22px;
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px #00000040;
    background-color: $white;


    img {
      display: block;
      width: 100%;
      height: 100%;
    }

    span {
      //@include font(500, start, Montserrat-Regular, 1.5rem, 1.875rem);
      @include font(500, start, Montserrat-Regular, 24px, 30px);
    }
  }

  &_data {
    max-width: 65%;

    &>p {
      overflow: hidden;
    }


    .name {
      white-space: nowrap;
      //@include font(700, start, Montserrat-Regular, 1.25rem, 1.5rem);
      @include font(700, start, Montserrat-Regular, 20px, 24px);
    }

    .role {
      font-style: italic;
      //@include font(500, start, Montserrat-Regular, 0.875rem, 1.063rem);
      @include font(500, start, Montserrat-Regular, 14px, 17px);
    }

    .position {
      //@include font(500, start, Montserrat-Regular, 0.875rem, 1.063rem);
      @include font(500, start, Montserrat-Regular, 14px, 17px);
    }

    .email {
      //@include font(500, start, Montserrat-Regular, 0.875rem, 1.063rem);
      @include font(300, start, Montserrat-Regular, 12px, 14px);
    }
  }
}

.title_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2px 10px;
  //@include font(700, start, Montserrat-Regular, 1.25rem, 1.5rem);
  @include font(700, center, Montserrat-Regular, 20px, 24px);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  &_name {
    white-space: nowrap;
    max-width: calc(100% - 31px);
    overflow: hidden;
  }

  &_link {
    width: 21px;
    height: 21px;
    cursor: pointer;
    margin-left: 5px;
    flex-shrink: 0;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &_hint {
    padding: 10px;
    margin-top: 10px;
    display: none;
    background-color: #D3D1D1;
    border-radius: 20px;
    @include font(600, center, Montserrat-Regular, 1.125rem, 1.375rem);
  }
}

.btn {
//  position: relative;
}

.btn + span {
 // z-index: 7;
 // position: absolute;
 // top: -30px;
 // left: 30%;
  margin-left: 10px;
  background: #141515;
  color: $white;
  padding: 5px;
  border-radius: 6px;
  display: none;
  @include font(400, center, Montserrat-Regular, 0.875rem, 1rem);
}



