@import './../../../assets/styles/helpers';
@import './../../../assets/styles/mixins';

.switch_account_window {
  width: 21.25rem;
  border: 1px solid #D6D1D1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: $white;
  overflow-y: auto;
  max-height: 85vh;

  &_header {
    padding: 10px 0;
    @include font(500, center, Montserrat-Regular, 1rem, 1.25rem);
  }
}

.switch_account_separator {
  width: 100%;
  height: 0;
  border: 1px solid #C5C0C0;
}

