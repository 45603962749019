@import './../../../../../assets/styles/helpers';
@import './../../../../../assets/styles/mixins';

.font {
  @include font(500, start, Montserrat-Medium, 1rem, 1.25rem)
}

.action_button {
  display: block;
  width: 100%;
  max-width: 140px;
  margin: 0 auto;
  border: none;
  padding: 5px 0;
  color: $link;
  background: $teamManagement_action_background;
  border-radius: 5px;
  @include font(700, center, Montserrat-Bold, 1rem, 1.25rem);
  cursor: pointer;
}