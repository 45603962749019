@import './../../../../../assets/styles/helpers';
@import './../../../../../assets/styles/mixins';


.mw_body {

  &_title {
    margin-top: 2rem;
    @include font(600, start, Montserrat-Regular, 1rem, 1.25rem);
    margin-bottom: 1.5rem;
  }
}

.mw_button {
  display: flex;
  justify-content: center;
  margin-top: 4rem;

  & > button:nth-child(1) {
    margin-right: 10px;
  }
}